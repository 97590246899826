@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Cooper-Std";
  src: url("../public/fonts/CooperBlackStd.eot");
  src: url("../public/fonts/CooperBlackStd.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/CooperBlackStd.woff2") format("woff2"),
    url("../public/fonts/CooperBlackStd.woff") format("woff"),
    url("../public/fonts/CooperBlackStd.ttf") format("truetype"),
    url("../public/fonts/CooperBlackStd.svg#CooperBlackStd") format("svg");
  /* font-weight: 300; */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'custom';
  src: url('../public/fonts/rockwell.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins-Regular.ttf") format("truetype"),
    url("../public/fonts/Poppins-SemiBold.ttf") format("truetype"),
    url("../public/fonts/Poppins-Bold.ttf") format("truetype");
  /* font-weight: 600; */
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "Poppins";
}

ol {
  list-style: auto;
  margin: 0;
  padding-left: 40px;
}

ul {
  list-style: auto;
  margin: 0;
  padding-left: 40px;
}

.text-stroke {
  -webkit-text-stroke: 0.5px rgb(255, 255, 255);
}

/* Customize scrollbar */
.aScrollBar::-webkit-scrollbar {
  width: 4px;
  border-radius: 6px;
}

.aScrollBar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
  border-radius: 6px;
}

.aScrollBar::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 6px;
}

.aScrollBar::-webkit-scrollbar-thumb:hover {
  background: darkred; /* Color of the thumb when hovered */
}
.global-title::after {
  content: "";
  border-bottom: 2px solid #ff6500;
  width: 60px;
  display: block;
  margin: 0 auto;
  padding-bottom: 5px;
}
:hover.global-title::after {
  transition-duration: 500ms;
  width: 120px;
}
:hover.global-title::before {
  transition-duration: 500ms;
  width: 120px;
}

.panalist-service-img img {
  --size: 255px;
  --f: 5;
  --radius: calc(var(--size) / var(--f));

  width: var(--size);
  height: auto;
  aspect-ratio: 1.155;
  object-fit: cover;
  --cg: #0000, #000 1deg 119deg, #0000 120deg;
  --rad: radial-gradient(farthest-side, #000 99%, #0000 101%);
  --s: calc(2 * var(--radius)) calc(2 * var(--radius));
  -webkit-mask: var(--rad) left calc(0.25 * var(--size) - 0.4 * var(--radius))
      top 0 / var(--s),
    var(--rad) right calc(0.25 * var(--size) - 0.4 * var(--radius)) top 0 /
      var(--s),
    var(--rad) left calc(0.25 * var(--size) - 0.4 * var(--radius)) bottom 0 /
      var(--s),
    var(--rad) right calc(0.25 * var(--size) - 0.4 * var(--radius)) bottom 0 /
      var(--s),
    var(--rad) left calc(0.15 * var(--radius)) top 50% / var(--s),
    var(--rad) right calc(0.15 * var(--radius)) top 50% / var(--s),
    conic-gradient(
        from 30deg at left calc(-0.3 * var(--radius)) top 50%,
        var(--cg)
      )
      left calc(0.3 * var(--radius)) top 50% /50% calc(100% - 0.8 *
          var(--radius)),
    conic-gradient(
        from -150deg at right calc(-0.3 * var(--radius)) top 50%,
        var(--cg)
      )
      right calc(0.3 * var(--radius)) top 50% /50% calc(100% - 0.8 *
          var(--radius)),
    linear-gradient(#000 0 0) center/calc(45% - 1.1 * var(--radius));
  -webkit-mask-repeat: no-repeat;
  cursor: pointer;
  transition: --radius 0.3s linear;
}

.embla {
  max-width: 70rem;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-spacing-sm: 1.6rem;
  --slide-size-sm: 50%;
  --slide-spacing-lg: 4rem;
  --slide-size-lg: calc(100% / 4);
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
@media (min-width: 750px) {
  .embla__container {
    margin-left: calc(var(--slide-spacing-sm) * -1);
  }
}
@media (min-width: 1200px) {
  .embla__container {
    margin-left: calc(var(--slide-spacing-lg) * -1);
  }
}
.embla__slide__hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
  flex: 0 0 100%;
  padding-left: var(--slide-spacing);
}
@media (min-width: 750px) {
  .embla__slide__hero {
    flex: 0 0 100%;
    padding-left: var(--slide-spacing-sm);
  }
}
@media (min-width: 1200px) {
  .embla__slide__hero {
    flex: 0 0 100%;
    padding-left: var(--slide-spacing-lg);
  }
}
.embla__slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
  flex: 0 0 var(--slide-size);
  padding-left: var(--slide-spacing);
}
@media (min-width: 750px) {
  .embla__slide {
    flex: 0 0 var(--slide-size-sm);
    padding-left: var(--slide-spacing-sm);
  }
}
@media (min-width: 1200px) {
  .embla__slide {
    flex: 0 0 var(--slide-size-lg);
    padding-left: var(--slide-spacing-lg);
  }
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  /* font-size: 4rem;
  font-weight: 600; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: var(--slide-height); */
}
.embla__controls {
  /* display: grid;
  grid-template-columns: auto 1fr; */
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
}
.embla__buttons {
  display: flex;
  justify-content: end;
  gap: 0.6rem;
  align-items: center;
}
.embla__button {
  /* -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5); */
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  /* width: 3.6rem; */
  /* height: 3.6rem; */
  /* z-index: 1; */
  border-radius: 16%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  /* -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5); */
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: "";
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
}

.text-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 14;
  line-height: 1.5; /* Adjust based on your design */
}
